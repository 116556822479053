import { makeStyles } from "@material-ui/core"
import classnames from "classnames"
import formatDistance from "date-fns/formatDistance"
import isAfter from "date-fns/isAfter"
import subDays from "date-fns/subDays"
import { pki } from "node-forge"
import * as React from "react"

import { SamlSettings } from "@cyphereye/schema/src/samlSettings"

import { FormRow } from "../../components/FormRowThreeCol"
import StaticTextField from "../../components/StaticTextField"

import DownloadCertificateButton from "./DownloadCertificateButton"

const useStyle = makeStyles((theme) => ({
  baseline: {
    alignSelf: "baseline",
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}))

export default function CertificateShow({ record }: any) {
  const classes = useStyle()

  const samlSettings: SamlSettings = record
  const { certificate } = samlSettings

  if (!certificate) {
    const Certificate = FormRow(StaticTextField, {
      className: classnames(classes.baseline, classes.error),
      value: "Certificate Missing",
    })
    return <Certificate label="Certificate" source="" />
  }

  const cert = pki.certificateFromPem(certificate)
  const subject = cert.subject.getField("CN")?.value
  const issuer = cert.issuer.getField("CN")?.value

  const expires = cert.validity.notAfter
  const expiresSoon = isAfter(new Date(), subDays(expires, 14))
  const expired = isAfter(new Date(), expires)
  const expiresValue = expired
    ? `Expired on ${expires.toLocaleString()}`
    : expires.toLocaleString()
  const expiresClass = classnames(classes.baseline, {
    [classes.error]: expired,
    [classes.warning]: expiresSoon,
  })
  let expiresDescription = ""
  if (expired) {
    expiresDescription = "Expired"
  } else if (expiresSoon) {
    expiresDescription = `Expires in ${formatDistance(new Date(), expires)}`
  }

  const Subject = FormRow(StaticTextField, {
    className: classes.baseline,
    value: subject,
  })
  const Issuer = FormRow(StaticTextField, {
    className: classes.baseline,
    value: issuer,
  })
  const Expires = FormRow(StaticTextField, {
    className: expiresClass,
    value: expiresValue,
  })
  const Download = FormRow(DownloadCertificateButton, {
    certificate,
  })

  return (
    <>
      <Download label="Service Provider Certificate" description="" source="" />
      <Subject label="Certificate Subject" description="" source="" />
      <Issuer label="Certificate Issuer" description="" source="" />
      <span className={expiresClass}>
        <Expires
          label="Certificate Expires"
          description={expiresDescription}
          source=""
        />
      </span>
    </>
  )
}
