import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import IconAdd from "@material-ui/icons/Add"
import * as React from "react"
import { Button } from "react-admin"

import deviceRegistration from "../api/deviceRegistration"
import useNotifyInfinite from "../util/useNotifyInfinite"

export default function AddUserButton() {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState<string>()
  const notifyInfinite = useNotifyInfinite()

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  async function handleAdd() {
    if (email) {
      try {
        await deviceRegistration(email)
        setOpen(false)
        notifyInfinite("Registration has been emailed to the user.", "info")
      } catch (error) {
        notifyInfinite(error.message, "warning")
      }
    }
  }

  return (
    <>
      <Button label="Send Registration Email" onClick={handleOpen}>
        <IconAdd />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Send Registration Email
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter user's email to register new device.
          </DialogContentText>
          <TextField
            autoFocus
            label="Email"
            margin="dense"
            onChange={handleChange}
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleClose} color="primary" />
          <Button
            label="Add"
            onClick={handleAdd}
            color="primary"
            disabled={!email}
            type="submit"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
