import { stringify } from "qs"
import { fetchUtils, DataProvider, Record as AdminRecord } from "ra-core"

const httpClient = fetchUtils.fetchJson
const countHeader = "X-Total-Result-Count"

interface DataProviderOptions {
  apiUrl: string

  // For mapping custom PKs to `id`, e.g. { settings: "key" }
  primaryKeys?: Record<string, string>
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * Based on https://github.com/marmelab/react-admin/tree/master/packages/ra-data-simple-rest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sortBy=title&sortOrder=ASC&offset=0&limit=24
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 */
export default ({
  apiUrl,
  primaryKeys = {},
}: DataProviderOptions): DataProvider => {
  const pkForResource = (resource: string) => primaryKeys[resource] || "id"

  const mapPkToId = <RecordType extends AdminRecord = AdminRecord>(
    resource: string,
    json: RecordType
  ): RecordType => {
    const pkName = pkForResource(resource)
    return { ...json, id: json[pkName] }
  }

  return {
    getList: async (resource, params) => {
      const { filter, pagination, sort } = params
      const { page, perPage } = pagination
      const { field, order } = sort

      const rangeStart = (page - 1) * perPage

      const query = {
        limit: perPage,
        offset: rangeStart,
        sortBy: field === "id" ? pkForResource(resource) : field,
        sortOrder: order,
        filter,
      }
      const url = `${apiUrl}/${resource}?${stringify(query)}`

      const { headers, json } = await httpClient(url, {
        credentials: "include",
      })
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        )
      }
      return {
        data: json.map((record: any) => mapPkToId(resource, record)),
        total: Number(headers.get(countHeader.toLowerCase()) || "0"),
      }
    },

    getOne: async (resource, params) => {
      const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
        credentials: "include",
      })
      return {
        data: mapPkToId(resource, json),
      }
    },

    getMany: async (resource, params) => {
      const query = {
        filter: { [pkForResource(resource)]: params.ids },
      }
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      const { json } = await httpClient(url, {
        credentials: "include",
      })
      return {
        data: json.map((record: any) => mapPkToId(resource, record)),
      }
    },

    getManyReference: async (resource, params) => {
      const { filter, id, pagination, sort, target } = params
      const { page, perPage } = pagination
      const { field, order } = sort

      const rangeStart = (page - 1) * perPage

      const query = {
        limit: perPage,
        offset: rangeStart,
        sortBy: field,
        sortOrder: order,
        filter: stringify({
          ...filter,
          [target]: id,
        }),
      }
      const url = `${apiUrl}/${resource}?${stringify(query)}`

      const { headers, json } = await httpClient(url, {
        credentials: "include",
      })
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        )
      }
      return {
        data: json.map((record: any) => mapPkToId(resource, record)),
        total: Number(headers.get(countHeader.toLowerCase()) || "0"),
      }
    },

    update: async (resource, params) => {
      const { data, id } = params

      const { json } = await httpClient(`${apiUrl}/${resource}/${id}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(data),
      })
      return { data: mapPkToId(resource, json) }
    },

    // We don't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: async (resource, params) => {
      const responses = await Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      )
      return { data: responses.map(({ json }) => json.id) }
    },

    create: async (resource, params) => {
      const { json } = await httpClient(`${apiUrl}/${resource}`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params.data),
      })
      return { data: mapPkToId(resource, json) }
    },

    delete: async (resource, params) => {
      const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
        credentials: "include",
        method: "DELETE",
      })
      return { data: mapPkToId(resource, json) }
    },

    // We don't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: async (resource, params) => {
      await Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            credentials: "include",
            method: "DELETE",
          })
        )
      )
      // Just return the ids from params they should be the same, else Promise.all will fail
      return { data: params.ids }
    },
  }
}
