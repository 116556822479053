import { FormControlLabel, Checkbox, Typography } from "@material-ui/core"
import * as React from "react"
import { useInput } from "react-admin"

import { IntegrationStatus } from "@cyphereye/schema/src/integration"

export default function ActiveCheckbox(props: any) {
  const {
    label,
    fullWidth,
    helperText,
    options,
    disabled,
    ...restProps
  } = props

  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProps },
  } = useInput({ type: "text", ...restProps, source: "status" })

  const handleChange = React.useCallback(
    (_event, newValue) =>
      finalFormOnChange(
        newValue ? IntegrationStatus.Active : IntegrationStatus.Disabled
      ),
    [finalFormOnChange]
  )

  if (!props.record) {
    return null
  }

  const checkboxValue = value === IntegrationStatus.Active

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...inputProps}
          {...options}
          id={id}
          color="primary"
          disabled={disabled}
          checked={checkboxValue}
          onChange={handleChange}
        />
      }
      label={<Typography>Active?</Typography>}
      labelPlacement="start"
    />
  )
}
