import { v4 as uuidv4 } from "uuid"
import { z } from "zod"

import { date } from "./isoDate"
import pemCertificate from "./pemCertificate"
import requireFields from "./requireFields"
import stringToNumber from "./stringToNumber"

export enum IntegrationStatus {
  Active = "active",
  Disabled = "disabled",
}

export enum IntegrationType {
  Saml = "saml",
}

const baseSchema = z.object({
  id: z.string().uuid().default(uuidv4),
  name: z.string(),
  status: z.nativeEnum(IntegrationStatus).default(IntegrationStatus.Disabled),
  type: z.nativeEnum(IntegrationType).default(IntegrationType.Saml),
  meta: z
    .object({
      // type is Saml and status is Active
      entityId: z.optional(z.string()),
      assertionConsumerService: z.optional(z.string().url()),
      certificate: z.optional(pemCertificate),
      metadataUrl: z.optional(z.string().url()),
      policy: z.optional(
        z.object({
          trustScoreThreshold: stringToNumber(z.number().min(0).max(1)),
        })
      ),
    })
    .default({}),
  created: z.optional(date),
  updated: z.optional(date),
})

const refinement = (value: Partial<Integration>, ctx: z.RefinementCtx) => {
  if (value.status === IntegrationStatus.Active) {
    requireFields(
      value.meta,
      ctx,
      ["meta"],
      ["entityId", "assertionConsumerService", "certificate", "metadataUrl"]
    )
  }
}

export const schema = baseSchema.superRefine(refinement)

export const partialSchema = baseSchema.partial().superRefine(refinement)

export type Integration = z.infer<typeof schema>
