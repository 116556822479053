import * as React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { useForm } from "react-final-form"

import { CacheSettings } from "@cyphereye/schema/src/cacheSettings"

import CacheValidateButton from "./CacheValidateButton"

const CacheValidateButtonWrapper = () => {
  const form = useForm<CacheSettings>()
  return <CacheValidateButton form={form} />
}

export default function CacheSettingsEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <CacheValidateButtonWrapper />
    </Toolbar>
  )
}
