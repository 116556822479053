import * as React from "react"
import { Button, useNotify, useRefresh } from "react-admin"

import integrationRefreshMetadata from "../../api/integrationRefreshMetadata"
import useNotifyInfinite from "../../util/useNotifyInfinite"

const RefreshMetadataButton = ({
  record,
  resource,
  ...rest
}: {
  record?: any
  resource?: string
}) => {
  const notifyInfinite = useNotifyInfinite()
  const notify = useNotify()
  const refresh = useRefresh()

  // NOTE: we have to handle undefined record as react-admin clones components rather than rendering them.
  const id = record ? record.id : ""

  if (!record) {
    return null
  }

  const onClick = async () => {
    try {
      await integrationRefreshMetadata(id)
      refresh()
      notify("Metadata refreshed")
    } catch (error) {
      notifyInfinite(error?.response?.data?.error || error.message, "warning")
    }
  }

  return <Button label="Refresh" onClick={onClick} {...rest} />
}

export default RefreshMetadataButton
