import * as React from "react"
import { Filter, TextInput } from "react-admin"

const ObjectFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" resettable source="q" alwaysOn />
  </Filter>
)

export default ObjectFilter
