import { SamlMetadata } from "@cyphereye/schema/src/samlMetadata"

import { callAdminApi } from "."

export default async function integrationMetadata(
  url: string
): Promise<SamlMetadata> {
  const json = await callAdminApi({
    path: "integrations/metadata",
    data: { url },
  })
  return json.metadata
}
