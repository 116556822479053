import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import ErrorIcon from "@material-ui/icons/Error"
import * as React from "react"
import { Error, Loading, useQuery, Title } from "react-admin"
import { CacheValidateButton } from "../resources/cacheSettings"
import { EmailValidateButton } from "../resources/emailSettings"
import { LdapValidateButton } from "../resources/ldapSettings"

export default () => {
  // Not sure why useGetOne never returned data, but this works.
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "info",
    payload: { id: "info" },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!data) {
    return null
  }

  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Server Version</TableCell>
              <TableCell>{data.version}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>LDAP</TableCell>
              <TableCell>
                {data.ldapStatus ? <CheckIcon /> : <ErrorIcon />}
              </TableCell>
              <TableCell>
                <LdapValidateButton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                {data.emailStatus ? <CheckIcon /> : <ErrorIcon />}
              </TableCell>
              <TableCell>
                <EmailValidateButton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cache</TableCell>
              <TableCell>
                {data.cacheStatus ? <CheckIcon /> : <ErrorIcon />}
              </TableCell>
              <TableCell>
                <CacheValidateButton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Devices</TableCell>
              <TableCell>
                {data.deviceCountByStatus
                  .map((o: any) => `${o.status}: ${o.count}`)
                  .join(", ")}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Active Integrations</TableCell>
              <TableCell>{data.activeIntegrationCount}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Active Devices</TableCell>
              <TableCell>{data.accessTokenCount}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
