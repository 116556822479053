import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { AppBar } from "react-admin"

import logo from "./cyphereye-single-line-logo.png"
import { GlobalCss } from "./theme"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
})

const MyAppBar = (props: any) => {
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <GlobalCss />
      <img
        style={{ height: "2rem", marginLeft: "1em" }}
        alt="CypherEye Admin"
        src={logo}
      />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  )
}

export default MyAppBar
