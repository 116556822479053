import * as React from "react"
import { Button } from "@material-ui/core"
import DownloadableFieldButton from "../../components/DownloadableFieldButton"
import { samlApiUrl } from "../../api"

interface Props {
  record?: Record<string, any>
}

const DownloadButton = (props: any) => {
  const { className, urlToOpen } = props
  return (
    <div className={className}>
      <Button variant="contained" color="primary" download href={urlToOpen}>
        Download Metadata
      </Button>
    </div>
  )
}

export default function DownloadMetadataButton({ record = {} }: Props) {
  const urlToOpen = samlApiUrl(`${record.id}/metadata.xml`)

  return (
    <DownloadableFieldButton
      button={DownloadButton}
      description="XML file for our Identity Provider configuration"
      label="IdP Metadata Document"
      urlToOpen={urlToOpen}
    />
  )
}
