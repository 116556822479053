import SettingsPageRoute from "../../routes/SettingsPageRoute"
import LdapSettingsEdit from "./LdapSettingsEdit"

// Don't render as a component else the route does not work
const ldapSettingsEditRoute = () =>
  SettingsPageRoute({
    component: LdapSettingsEdit,
    id: "ldap",
    path: "/ldap",
    resource: "ldapSettings",
  })

export default ldapSettingsEditRoute
