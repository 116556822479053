import RichTextInput from "ra-input-rich-text"
import * as React from "react"
import { Edit, FormDataConsumer, SimpleForm, TextInput } from "react-admin"

import { schema } from "@cyphereye/schema/src/emailTemplate"

import makeTransformer from "../../util/makeTransformer"
import makeValidator from "../../util/makeValidator"
import { QuillToolbarDropDown } from "./DynamicQuillTools"
import EmailTemplateEditToolbar from "./EmailTemplateEditToolbar"

const transform = makeTransformer(schema)
const validate = makeValidator(schema)

const toolbar = [
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  ["bold", "italic", "underline", "strike"],
  ["image", "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }, { direction: "rtl" }],

  ["clean"],
]

function configureQuill(quill: any, fields: Record<string, string>) {
  const myDropDown = new QuillToolbarDropDown({
    label: "Insert Field...",
    rememberSelection: false,
  })

  myDropDown.setItems(fields)

  myDropDown.onSelect = (_, value) => {
    // Do whatever you want with the new dropdown selection here

    // For example, insert the value of the dropdown selection:
    const { index, length } = quill.selection.savedRange
    quill.deleteText(index, length)
    quill.insertText(index, value)
    quill.setSelection(index + value.length)
  }

  myDropDown.attach(quill)
}

const EmailTemplateTitle = ({ record }: { record?: any }) => {
  return <span>{record ? record.id : "Email Template"}</span>
}

const EmailTemplateEdit = (props: any) => (
  <Edit {...props} title={<EmailTemplateTitle />} transform={transform}>
    <SimpleForm
      toolbar={<EmailTemplateEditToolbar />}
      warnWhenUnsavedChanges
      validate={validate}
    >
      <TextInput disabled fullWidth source="description" />
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <RichTextInput
            configureQuill={(quill: any) =>
              configureQuill(quill, formData.fields)
            }
            label="Subject"
            options={{ formats: [] }}
            source="subject"
            toolbar={[]}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }: { formData: any }) => (
          <RichTextInput
            configureQuill={(quill: any) =>
              configureQuill(quill, formData.fields)
            }
            label="Source"
            source="source"
            toolbar={toolbar}
          />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)

export default EmailTemplateEdit
