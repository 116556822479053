import { FormApi } from "final-form"
import { fetchUtils } from "ra-core"
import * as React from "react"

import { CompanySettings } from "@cyphereye/schema/src/companySettings"

import useNotifyInfinite from "../../util/useNotifyInfinite"

interface Props {
  form: FormApi<CompanySettings>
}

export default function SetSiteSettingsButton({ form }: Props) {
  const notifyInfinite = useNotifyInfinite()

  React.useEffect(() => {
    async function run() {
      const { hostname, origin } = window.location

      function dnsLookupFail(message?: string) {
        notifyInfinite(
          `Unable to resolve ${hostname}${message ? `: ${message}` : ""}`,
          "warning"
        )
      }

      const settings = form.getState().values

      if (!settings.siteUrl) {
        form.change("siteUrl", origin)
      }

      if (!settings.siteIp) {
        const { json } = await fetchUtils.fetchJson(
          `https://dns.google/resolve?name=${hostname}`
        )

        if (json.Status !== 0) {
          dnsLookupFail()
        } else if (json.Answer.length > 1) {
          dnsLookupFail("resolved to multiple IP addresses")
        } else {
          form.change("siteIp", json.Answer[0].data)
        }
      }
    }
    run()
  }, [form, notifyInfinite])

  return null
}
