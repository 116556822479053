import * as React from "react"
import { useForm } from "react-final-form"

import { schema, CompanySettings } from "@cyphereye/schema/src/companySettings"

import EditSettingsPage from "../../components/EditSettingsPage"
import { TextInputRow } from "../../components/FormRowThreeCol"
import SetSiteSettings from "./SetSiteSettings"

const SetSiteSettingsWrapper = () => {
  const form = useForm<CompanySettings>()
  return <SetSiteSettings form={form} />
}

export default function CompanySettingsEdit(props: any) {
  return (
    <EditSettingsPage {...props} title="Organization Settings" schema={schema}>
      <TextInputRow
        label="Organization's Name"
        description="Name will appear on web pages and email notifications."
        source="name"
      />
      <SetSiteSettingsWrapper />
      <TextInputRow
        label="Public URL"
        description="Public URL of the server"
        source="siteUrl"
      />
      <TextInputRow
        label="Public IP"
        description="Public IP address of the server"
        source="siteIp"
      />
    </EditSettingsPage>
  )
}
