import { createTheme } from "@material-ui/core/styles"
import withStyles from "@material-ui/core/styles/withStyles"

export const ThemeColors = {
  DarkBlue: "#214e8c",
  DarkGray: "#231f10",
  DarkGreen: "#498240",
  LightBlue: "#586ebf",
  LightGray: "#f2f2f3",
  BlueBackground: "#DADFF1",
  Orange: "#dc661e",
  White: "#ffffff",
}

export const buttonHover = {
  textDecoration: "underline",
  textUnderlineOffset: ".25em",
  textDecorationColor: ThemeColors.Orange,
}

const defaultTheme = createTheme()

export default createTheme({
  overrides: {
    MuiContainer: {
      root: {
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: ThemeColors.White,
        backgroundColor: ThemeColors.DarkGreen,
      },
      colorSecondary: {
        backgroundColor: ThemeColors.LightGray,
      },
    },
    MuiButton: {
      textSizeSmall: {
        margin: 0,
        padding: 0,
      },
      root: {
        "&:hover": {
          ...buttonHover,
          backgroundColor: "none",
        },
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
        marginBottom: "1rem",
      },
    },
    MuiLink: {
      underlineHover: {
        color: ThemeColors.Orange,
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: ThemeColors.Orange,
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 400,
      },
    },
    // @ts-ignore
    RaMenuItemLink: {
      active: {
        backgroundColor: ThemeColors.DarkBlue,
        color: ThemeColors.White,
      },
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: ThemeColors.BlueBackground,
      },
    },
    RaTopToolbar: {
      root: {
        "& button": {
          marginRight: "3px",
          marginLeft: "9px",
        },
      },
    },
    RaButton: {
      label: {
        paddingLeft: 0,
      },
    },
    RaDeleteWithUndoButton: {
      deleteButton: {
        color: ThemeColors.Orange,
        "&:hover": {
          backgroundColor: "none",
          textDecoration: "underline",
          textUnderlineOffset: ".25em",
        },
      },
    },
  },
  palette: {
    primary: {
      main: ThemeColors.Orange,
    },
    secondary: {
      main: ThemeColors.LightGray,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
})

export const GlobalCss = withStyles({
  "@global": {
    body: {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    ".MuiAppBar-root": {
      "& .MuiToolbar-root": {
        "& > .MuiButtonBase-root": {
          display: "none",
        },
        display: "flex",
        justifyContent: "space-between",
      },
      "& h6": {
        flex: "none",
      },
    },
    ".MuiDrawer-paper": {
      width: "240px",
    },
    ".MuiToolbar": {
      display: "flex",
      // justify-content: "space-between",
    },
    ".MuiTableRow-root": {
      verticalAlign: "baseline",
      "& .MuiTableCell-paddingCheckbox": {
        verticalAlign: "middle",
      },
    },
    ".MuiListItem-button:hover": {
      backgroundColor: ThemeColors.LightBlue,
      color: ThemeColors.White,
    },
    ".MuiTableCell-root": {
      fontSize: "1rem",
    },
    ".MuiTableRow-head": {
      backgroundColor: ThemeColors.BlueBackground,
    },
  },
})(() => null)
