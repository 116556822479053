import * as React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { useForm } from "react-final-form"

import { LdapSettings } from "@cyphereye/schema/src/ldapSettings"

import LdapValidateButton from "./LdapValidateButton"

const LdapValidateButtonWrapper = () => {
  const form = useForm<LdapSettings>()
  return <LdapValidateButton form={form} />
}

export default function LdapSettingsEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <LdapValidateButtonWrapper />
    </Toolbar>
  )
}
