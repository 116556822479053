import { pki } from "node-forge"
import { z, ZodIssueCode } from "zod"

const pemCertificate = z.string().superRefine((value, ctx) => {
  try {
    pki.certificateFromPem(value)
  } catch (error) {
    const err: any = error
    ctx.addIssue({ code: ZodIssueCode.custom, message: err.message })
  }
})

export default pemCertificate
