import * as React from "react"
import { Datagrid, DateField, List, TextField } from "react-admin"

const SettingList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="key" />
      <TextField source="value" />
      <TextField source="type" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
  </List>
)

export default SettingList
