import { z } from "zod"

import { date } from "./isoDate"
import requireFields from "./requireFields"
import stringToNumber from "./stringToNumber"

export enum EmailProtocol {
  Smtp = "smtp",
  Mailgun = "mailgun",
}

const baseSchema = z.object({
  id: z.literal("email").default("email"),
  protocol: z.nativeEnum(EmailProtocol).default(EmailProtocol.Smtp),
  username: z.string().email(),
  from: z.string(),
  created: z.optional(date),
  updated: z.optional(date),
  // SMTP
  host: z.optional(z.string()),
  port: z.optional(stringToNumber(z.number().int().min(1).max(65535))),
  password: z.optional(z.string()),
  // Mailgun
  mailgunApiKey: z.optional(z.string().nullable()),
  mailgunDomain: z.optional(z.string().nullable()),
})

const refinement = (value: Partial<EmailSettings>, ctx: z.RefinementCtx) => {
  switch (value.protocol) {
    case EmailProtocol.Smtp:
      requireFields(value, ctx, [], ["host", "port", "password"])
      break
    case EmailProtocol.Mailgun:
      requireFields(value, ctx, [], ["mailgunApiKey", "mailgunDomain"])
      break
    default:
      break
  }
}

export const schema = baseSchema.superRefine(refinement)

export const partialSchema = baseSchema.partial().superRefine(refinement)

export type EmailSettings = z.infer<typeof schema>
