import { LdapSettings } from "@cyphereye/schema/src/ldapSettings"

import { callAdminApi } from "."

export default async function ldapSettingsValidate(settings?: LdapSettings) {
  return callAdminApi({
    path: "ldapSettings/validate",
    data: settings ? JSON.stringify({ settings }) : undefined,
  })
}
