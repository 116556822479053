import { Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as React from "react"

import logo from "./cyphereye-multi-line-logo.png"
import { ThemeColors } from "./theme"

const useStyles = makeStyles({
  footer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem 0rem 1rem 0rem",
    width: "100%",
    marginTop: "1.5rem",
  },
  link: {
    marginTop: ".2rem",
  },
  logo: {
    marginTop: "2rem",
  },
})

export default function GetTheAppFooter() {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Typography align="center">Get the CypherEye App</Typography>
      <Link
        className={classes.link}
        href="https://apps.apple.com/us/app/cyphereye/id1527297688"
      >
        <Typography color="textPrimary">iOS</Typography>
      </Link>
      <Link
        className={classes.link}
        href=" https://play.google.com/store/apps/details?id=com.cyphereye.cyphereye "
      >
        <Typography color="textPrimary">Android</Typography>
      </Link>
      <a
        className={classes.logo}
        href="https://cyphereye.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="CypherEye" src={logo} />
      </a>
    </div>
  )
}
