import { Button, makeStyles } from "@material-ui/core"
import * as React from "react"

import downloadCertificate from "../../util/downloadCertificate"

const useStyle = makeStyles((theme) => ({
  button: { marginBottom: theme.spacing(1) },
}))

export default function DownloadCertificateButton({
  certificate,
}: {
  certificate?: string
}) {
  const classes = useStyle()

  return (
    <Button
      disabled={!certificate}
      variant="contained"
      color="primary"
      onClick={() => {
        if (certificate) {
          downloadCertificate(certificate)
        }
      }}
      className={classes.button}
    >
      Download Certificate
    </Button>
  )
}
