import * as React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"

import { Integration } from "@cyphereye/schema/src/integration"

import { TextInputRow } from "../../components/FormRowThreeCol"

import DownloadMetadataButton from "./DownloadMetadataButton"
import ActiveCheckbox from "./ActiveCheckbox"
import useStyles from "./styles"
import { transform, validate } from "./validation"
import IntegrationAuthUrl from "./IntegrationAuthUrl"
import CertificateShow from "./CertificateShow"

const IntegrationEditTitle = ({ record }: { record?: Integration }) => {
  return <span>Edit {record?.name}</span>
}

const IntegrationEdit = (props: any) => {
  const classes = useStyles()

  return (
    <Edit {...props} title={<IntegrationEditTitle />} transform={transform}>
      <SimpleForm warnWhenUnsavedChanges validate={validate}>
        <TextInputRow
          source="name"
          label="Name"
          description="Name will appear on sign in page and email notifications."
        />

        <DownloadMetadataButton />
        <IntegrationAuthUrl />
        <ActiveCheckbox />
        <TextInputRow
          source="meta.policy.trustScoreThreshold"
          label="Trust Score Threshold"
          description="Minimum trust score required to sign in"
        />
        <TextInputRow
          source="meta.entityId"
          label="SAML SP Entity ID"
          description="Service Provider (SP) unique identifier"
        />
        <TextInputRow
          source="meta.assertionConsumerService"
          label="Assertion Consumer Service"
          description="The SP sign-in URL. Endpoint provided by the SP where SAML responses are posted."
        />
        <TextInputRow
          source="meta.metadataUrl"
          label="SP Metadata Document"
          description="XML file for the Service Provider configuration"
        />

        <CertificateShow />
        <TextInput
          source="meta.certificate"
          label=""
          className={classes.preTextArea}
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  )
}

export default IntegrationEdit
