import SettingsPageRoute from "../../routes/SettingsPageRoute"
import CacheSettingsEdit from "./CacheSettingsEdit"

// Don't render as a component else the route does not work
const cacheSettingsEditRoute = () =>
  SettingsPageRoute({
    component: CacheSettingsEdit,
    id: "cache",
    path: "/cache",
    resource: "cacheSettings",
  })

export default cacheSettingsEditRoute
