import * as React from "react"
import { Datagrid, DateField, List, EditButton, TextField } from "react-admin"

import { withStyles } from "@material-ui/core/styles"
import RefreshMetadataButton from "./RefreshMetadataButton"
import UpdateStatusButton from "./UpdateStatusButton"
import IntegrationListActions from "./IntegrationListActions"
import { buttonHover } from "../../components/theme"

const actionButtonStyles = {
  label: {
    padding: 0,
  },
  button: {
    minWidth: "auto",
    padding: 0,
    "&:hover .MuiButton-label": {
      ...buttonHover,
    },
  },
}

const EmbeddedEditButton = withStyles(actionButtonStyles)(EditButton)
const EmbeddedStatusButton = withStyles(actionButtonStyles)(UpdateStatusButton)
const EmbeddedRefreshMetadataButton = withStyles(actionButtonStyles)(
  RefreshMetadataButton
)

const ButtonSeparator = () => <span style={{ margin: "0 .4em" }}>|</span>

const TextFieldWithActions = (props: any) => (
  <div>
    <TextField {...props} />
    <div style={{ display: "flex", alignItems: "center", height: "20px" }}>
      <EmbeddedEditButton {...props} icon={null} />
      <ButtonSeparator />
      <EmbeddedStatusButton {...props} icon={null} />
      <ButtonSeparator />
      <EmbeddedRefreshMetadataButton {...props} icon={null} />
    </div>
  </div>
)

const IntegrationList = (props: any) => (
  <List {...props} actions={<IntegrationListActions />}>
    <Datagrid>
      <TextFieldWithActions source="name" />
      <TextField source="status" />
      <TextField source="type" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
  </List>
)

export default IntegrationList
