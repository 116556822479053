import * as React from "react"

import { confirmPasswordSchema } from "@cyphereye/schema/src/adminPassword"

import EditSettingsPage from "../../components/EditSettingsPage"
import { PasswordInputRow } from "../../components/FormRowThreeCol"

export default function AdminPasswordEdit(props: any) {
  return (
    <EditSettingsPage
      {...props}
      title="Admin Password"
      schema={confirmPasswordSchema}
    >
      <PasswordInputRow
        description="Password for the Admin Console"
        label="Password"
        source="password"
      />
      <PasswordInputRow
        description="Re-enter password to confirm"
        label="Confirm Password"
        source="passwordConfirmation"
      />
    </EditSettingsPage>
  )
}
