import SettingsPageRoute from "../../routes/SettingsPageRoute"
import AdminPasswordEdit from "./AdminPasswordEdit"

// Don't render as a component else the route does not work
export default function adminPasswordEditRoute() {
  return SettingsPageRoute({
    component: AdminPasswordEdit,
    id: "adminPassword",
    path: "/adminPassword",
    resource: "adminPassword",
  })
}
