import { z } from "zod"

const pattern = /^[0-9a-fA-F]{64}$/

// eslint-disable-next-line no-template-curly-in-string
export const message = "Should be exactly 64 hex characters"

const aesKey = z.string().refine((value) => !!value.match(pattern), { message })

export default aesKey
