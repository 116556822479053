import * as React from "react"
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin"

import InlineSvg from "../../components/InlineSvg"

const SvgField = ({
  source = "",
  record = {},
}: {
  source: string
  record?: Record<string, any>
}) => {
  const svg = record[source]

  return <InlineSvg svg={svg} />
}

const ObjectShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="user" />
      <TextField source="type" />
      <DateField source="expires" />
      <DateField source="created" />
      <DateField source="updated" />
      <SvgField source="svg" />
    </SimpleShowLayout>
  </Show>
)

export default ObjectShow
