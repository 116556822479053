import {
  schema,
  IntegrationStatus,
  IntegrationType,
} from "@cyphereye/schema/src/integration"

import makeTransformer from "../../util/makeTransformer"
import makeValidator from "../../util/makeValidator"

export const statusChoices = Object.values(IntegrationStatus).map((value) => ({
  id: value,
  name: value,
}))

export const typeChoices = Object.values(IntegrationType).map((value) => ({
  id: value,
  name: value,
}))

export const transform = makeTransformer(schema)
export const validate = makeValidator(schema)

export const getInitialValues = () =>
  schema.parse({
    name: "",
  })
