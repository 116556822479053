import * as React from "react"
import { Button, useNotify, useUpdate } from "react-admin"

import { IntegrationStatus } from "@cyphereye/schema/src/integration"

const UpdateStatusButton = ({
  record,
  resource,
  ...rest
}: {
  record?: any
  resource?: string
}) => {
  // NOTE: we have to handle undefined record as react-admin clones components rather than rendering them.
  const id = record ? record.id : ""
  const status = record?.status
  const newStatus =
    status === IntegrationStatus.Active
      ? IntegrationStatus.Disabled
      : IntegrationStatus.Active
  const buttonLabel =
    newStatus === IntegrationStatus.Active ? "Activate" : "Suspend"
  const notifyText = `Integration ${
    newStatus === IntegrationStatus.Active ? "Activated" : "Disabled"
  }`

  const [update, { loading }] = useUpdate(
    resource,
    id,
    { id, status: newStatus },
    record
  )
  const notify = useNotify()

  if (!record) {
    return null
  }

  const onClick = async () => {
    await update()
    notify(notifyText)
  }

  return (
    <Button
      disabled={loading}
      label={buttonLabel}
      onClick={onClick}
      {...rest}
    />
  )
}

export default UpdateStatusButton
