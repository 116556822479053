import { makeStyles } from "@material-ui/core"
import { pki } from "node-forge"
import * as React from "react"

import { SamlSettings } from "@cyphereye/schema/src/samlSettings"

import { FormRow } from "../../components/FormRowThreeCol"
import StaticTextField from "../../components/StaticTextField"

const useStyle = makeStyles((theme) => ({
  valid: {
    alignSelf: "baseline",
  },
  invalid: {
    alignSelf: "baseline",
    color: theme.palette.error.main,
  },
}))

export default function PrivateKey({ record }: any) {
  const classes = useStyle()

  const samlSettings: SamlSettings = record
  const { certificate, privateKey } = samlSettings

  let value = "Private Key Missing"
  let valid = false

  if (privateKey) {
    if (!certificate) {
      value = "Certificate Missing"
    } else {
      const cert = pki.certificateFromPem(certificate)
      const publicKeyFromCert = pki.publicKeyToPem(cert.publicKey)

      const forgePrivateKey = pki.privateKeyFromPem(privateKey)
      const forgePublicKey = pki.setRsaPublicKey(
        forgePrivateKey.n,
        forgePrivateKey.e
      )
      const publicKeyFromPrivate = pki.publicKeyToPem(forgePublicKey)

      if (publicKeyFromCert !== publicKeyFromPrivate) {
        value = "Does not match Public Key in Certificate"
      } else {
        value = "Valid"
        valid = true
      }
    }
  }

  const PrivateKeyRow = FormRow(StaticTextField, {
    className: valid ? classes.valid : classes.invalid,
    value,
  })

  return <PrivateKeyRow label="Private Key" description="" source="" />
}
