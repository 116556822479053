import * as React from "react"
import { Layout } from "react-admin"
import { Helmet } from "react-helmet"

import AppBar from "./AppBar"
import Menu from "./Menu"

export default function AdminLayout(props: any) {
  return (
    <>
      <Helmet>
        <title>CypherEye Admin</title>
      </Helmet>
      <Layout {...props} appBar={AppBar} menu={Menu} />
    </>
  )
}
