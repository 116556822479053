import { z } from "zod"

import { date } from "./isoDate"

function stripParagraphTag(value: string) {
  return value.replace(/^<p>/, "").replace(/<\/p>$/, "")
}

export const schema = z.object({
  id: z.string(),
  description: z.string(),
  fields: z.record(z.string()),
  originalSource: z.string(),
  source: z.string(),
  subject: z.string().transform(stripParagraphTag),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type EmailTemplate = z.infer<typeof schema>
