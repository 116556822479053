import { createBrowserHistory } from "history"
import polyglotI18nProvider from "ra-i18n-polyglot"
import englishMessages from "ra-language-english"
import * as React from "react"
import { Admin, Resource } from "react-admin"

import { adminApiUrl } from "./api"
import createAuthProvider from "./authProvider"
import AdminLayout from "./components/AdminLayout"
import Dashboard from "./components/Dashboard"
import Login from "./components/Login"
import theme from "./components/theme"
import createDataProvider from "./dataProvider"
import emailTemplates from "./resources/emailTemplates"
import integrations from "./resources/integrations"
import objects from "./resources/objects"
import devices from "./resources/devices"
import settings from "./resources/settings"
import users from "./resources/users"
import customRoutes from "./routes"
import "./util/hideKnownErrors"

const history = createBrowserHistory()

const authProvider = createAuthProvider({ apiUrl: adminApiUrl })

const dataProvider = createDataProvider({
  apiUrl: adminApiUrl,
  primaryKeys: { settings: "key" },
})

// Prevent missing translations error logs
// https://github.com/marmelab/react-admin/issues/758#issuecomment-684611947
const i18nProvider = polyglotI18nProvider((locale) => englishMessages, "en", {
  allowMissing: true,
})

const App = () => (
  <Admin
    authProvider={authProvider}
    customRoutes={customRoutes}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    history={history}
    i18nProvider={i18nProvider}
    layout={AdminLayout}
    loginPage={Login}
    theme={theme}
  >
    <Resource {...objects} />
    <Resource {...integrations} />
    <Resource {...devices} />
    <Resource {...users} />
    <Resource {...emailTemplates} />
    <Resource {...settings} />
    <Resource name="adminPassword" />
    <Resource name="companySettings" />
    <Resource name="cacheSettings" />
    <Resource name="emailSettings" />
    <Resource name="info" />
    <Resource name="ldapSettings" />
    <Resource name="samlSettings" />
  </Admin>
)

export default App
