import DescriptionIcon from "@material-ui/icons/Description"

import EmailTemplateEdit from "./EmailTemplateEdit"
import EmailTemplateList from "./EmailTemplateList"
import EmailTemplateShow from "./EmailTemplateShow"

const resource = {
  edit: EmailTemplateEdit,
  icon: DescriptionIcon,
  list: EmailTemplateList,
  show: EmailTemplateShow,
  name: "emailTemplates",
}

export default resource
