import * as React from "react"
import { Create, SimpleForm, TextInput, Toolbar } from "react-admin"

import useStyles from "./styles"
import { getInitialValues, transform, validate } from "./validation"

import { StaticTextRow, TextInputRow } from "../../components/FormRowThreeCol"
import ActiveCheckbox from "./ActiveCheckbox"

const IntegrationCreate = (props: any) => {
  const classes = useStyles()

  // alwaysEnableSaveButton as form can be pre-populated with a record when
  // importing from XML metadata - see `ImportMetadataButton`.
  return (
    <Create {...props} title="New Integration" transform={transform}>
      <SimpleForm
        warnWhenUnsavedChanges
        initialValues={getInitialValues()}
        toolbar={<Toolbar alwaysEnableSaveButton />}
        validate={validate}
      >
        <TextInputRow
          label="Name"
          description="Name will appear on sign in page and email notifications."
          source="name"
        />
        <ActiveCheckbox />
        <TextInputRow
          label="SAML SP Entity ID"
          description="Service Provider (SP) unique identifier."
          source="meta.entityId"
        />
        <TextInputRow
          label="Assertion Customer Service"
          source="meta.assertionConsumerService"
          description="The SP sign-in URL. Endpoint provided by the SP where SAML responses are posted."
        />
        <TextInputRow
          label="SP Metadata Document"
          description="XML file for the Service Provider configuration."
          source="meta.metadataUrl"
        />
        <StaticTextRow
          label="Service Provider Certificate"
          description="Certificate in PEM format"
        />
        <TextInput
          label="add the certificate"
          source="meta.certificate"
          className={classes.preTextArea}
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  )
}

export default IntegrationCreate
