import * as React from "react"

import { FormRow } from "./FormRowThreeCol"

interface Props {
  button: React.ElementType
  description: string
  label: string
  [rest: string]: any
}

export default function DownloadableFieldButton({
  button,
  description,
  label,
  ...rest
}: Props) {
  const FieldRow = FormRow(button, {
    ...rest,
    style: { marginBottom: "1rem" },
  })

  return <FieldRow description={description} label={label} />
}
