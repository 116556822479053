import axios, { AxiosError, Method } from "axios"

const apiVersion = "v2"
const apiUrl = "/api"

export const adminApiUrl = `${apiUrl}/admin`

export function publicApiUrl(path: string) {
  return `${apiUrl}/${apiVersion}/public/${path}`
}

export function samlApiUrl(path: string) {
  return `${apiUrl}/${apiVersion}/saml2a/${path}`
}

interface ApiCallOptions {
  data?: any
  method?: Method
  url: string
}

async function callApi({ data, method = "post", url }: ApiCallOptions) {
  try {
    const response = await axios({
      data,
      headers: {
        "Content-Type": "application/json",
      },
      method,
      responseType: "json",
      url,
    })

    return response.data
  } catch (error) {
    if (error.isAxiosError) {
      const axiosError = error as AxiosError
      if (axiosError.response?.data?.message) {
        throw new Error(axiosError.response.data.message)
      }
    }
    throw error
  }
}

interface AdminApiCallOptions {
  data?: any
  method?: Method
  path: string
}

export async function callAdminApi({ path, ...rest }: AdminApiCallOptions) {
  return callApi({ url: `${adminApiUrl}/${path}`, ...rest })
}

interface PublicApiCallOptions {
  data?: any
  path: string
}

// Public API always returns a `data` object inside the JSON.
export async function callPublicApi({ path, ...rest }: PublicApiCallOptions) {
  const json = await callApi({ url: publicApiUrl(path), ...rest })
  return json.data
}
