import SettingsPageRoute from "../../routes/SettingsPageRoute"
import SamlSettingsEdit from "./SamlSettingsEdit"

// Don't render as a component else the route does not work
const samlSettingsEditRoute = () =>
  SettingsPageRoute({
    component: SamlSettingsEdit,
    id: "saml",
    path: "/saml",
    resource: "samlSettings",
  })

export default samlSettingsEditRoute
