import * as React from "react"
import {
  Datagrid,
  DateField,
  DeleteButton,
  EmailField,
  List,
  ShowButton,
  TextField,
} from "react-admin"
import { withStyles } from "@material-ui/core/styles"

import FacetFilter from "../../components/FacetFilter"
import { buttonHover } from "../../components/theme"
import DeviceListActions from "./DeviceListActions"
import UpdateStatusButton from "./UpdateStatusButton"

const buttonStyle = {
  minWidth: "auto",
  padding: 0,
  borderRadius: 0,
  "&:hover .MuiButton-label": {
    ...buttonHover,
  },
}

const actionButtonStyles = {
  button: { ...buttonStyle },
}

const actionButtonStylesDelete = {
  deleteButton: { ...buttonStyle },
}

const EmbeddedUpdateStatusButton = withStyles(actionButtonStyles)(
  UpdateStatusButton
)
const EmbeddedDeleteButton = withStyles(actionButtonStylesDelete)(DeleteButton)
const EmbeddedShowButton = withStyles(actionButtonStyles)(ShowButton)

const TextFieldWithActions = (props: any) => (
  <div>
    <TextField {...props} />
    <div style={{ display: "flex", alignItems: "center", height: "20px" }}>
      <EmbeddedUpdateStatusButton {...props} className="embedded" />
      <span style={{ margin: "0 .4em" }}>|</span>
      <EmbeddedDeleteButton {...props} icon={null} className="embedded" />
      <span style={{ margin: "0 .4em" }}>|</span>
      <EmbeddedShowButton {...props} icon={null} label="view" />
    </div>
  </div>
)

const DeviceList = (props: any) => {
  return (
    <List
      {...props}
      title="User Devices"
      actions={<DeviceListActions />}
      filters={<FacetFilter resource="devices" />}
    >
      <Datagrid>
        <TextFieldWithActions source="name" />
        <TextField label="Device Name" source="alias" />
        <EmailField label="Email" source="userEmail" />
        <TextField source="status" />
        <DateField source="created" />
      </Datagrid>
    </List>
  )
}

export default DeviceList
