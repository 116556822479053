import mapValues from "lodash/mapValues"
import { z } from "zod"

// Makes a validator for react-admin from the given schema.
export default function makeValidator(schema: z.Schema<any>) {
  return (values: any) => {
    const result = schema.safeParse(values)
    if (result.success) {
      return {}
    }

    // Reformat errors.
    const fieldErrors: Record<string, string> = {}
    for (const issue of result.error.issues) {
      const path = issue.path.join(".")
      fieldErrors[path] = issue.message
    }
    return fieldErrors
  }
}
