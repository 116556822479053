import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as React from "react"

import GetTheAppFooter from "../GetTheAppFooter"
import InlineSvg from "../InlineSvg"
import TextSeparator from "../TextSeparator"

import waitForScan, { EyeStatus } from "./waitForScan"

const useStyles = makeStyles({
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  message: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  footer: {
    width: "100%",
  },
})

interface Props {
  eyeId: number
  data: {
    data: string
    message?: string
    svg: string
  }
}

export default function ScanInstructions({ eyeId, data }: Props) {
  const classes = useStyles()

  const [status, setStatus] = React.useState(EyeStatus.Pending)

  React.useEffect(() => {
    async function run() {
      const newStatus = await waitForScan(eyeId)
      setStatus(newStatus)
    }
    run()
  }, [eyeId])

  let message = (
    <>
      <p>
        Scan the CypherEye with your CypherEye App.<br></br>
        Make sure the mouse cursor does not obscure the image.
      </p>
    </>
  )

  if (status === EyeStatus.Scanned) {
    message = <p>The CypherEye code has been successfully scanned</p>
  } else if (status === EyeStatus.DeviceNotRegistered) {
    message = <p>This device is not registered</p>
  }

  return (
    <div className={classes.content}>
      <Typography className={classes.message} align="center" component="span">
        {message}
      </Typography>
      {status === EyeStatus.Pending && <InlineSvg svg={data.svg} />}
      <TextSeparator text="OR" />
      <div className={classes.footer}>
        <GetTheAppFooter />
      </div>
    </div>
  )
}
