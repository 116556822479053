import { EmailTemplate } from "@cyphereye/schema/src/emailTemplate"

import { callAdminApi } from "."

export default async function sendTestEmail(
  email: string,
  template: EmailTemplate
) {
  return callAdminApi({ path: "sendTestEmail", data: { email, template } })
}
