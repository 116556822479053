import { z } from "zod"

import { date } from "./isoDate"

function stripSlash(value: string) {
  return value.replace(/\/$/, "")
}

export const schema = z.object({
  id: z.literal("company").default("company"),
  name: z.string(),
  siteUrl: z.string().url().transform(stripSlash),
  siteIp: z
    .string()
    .regex(
      /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
      "siteIp must be a valid IP address"
    ),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type CompanySettings = z.infer<typeof schema>
