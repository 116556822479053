import { pki } from "node-forge"
import { z, ZodIssueCode } from "zod"

const pemPrivateKey = z.string().superRefine((value, ctx) => {
  try {
    pki.privateKeyFromPem(value)
  } catch (error) {
    const err: any = error
    ctx.addIssue({ code: ZodIssueCode.custom, message: err.message })
  }
})

export default pemPrivateKey
