import { Grid, makeStyles } from "@material-ui/core"
import * as React from "react"
import { PasswordInput, TextInput } from "react-admin"

import StaticTextField from "./StaticTextField"
import UrlFieldWithCopy from "./UrlFieldWithCopy"

const use3ColumnsStyle = makeStyles((theme) => ({
  col1: {
    flexBasis: "25%",
    marginBottom: "23px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  col2: {
    flexBasis: "33%",
    marginTop: 0,
    minWidth: 0,
    paddingRight: theme.spacing(1),
    boxSizing: "border-box",
  },
  col3: {
    flexBasis: "42%",
    marginBottom: "23px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

type GridRowProps = {
  label: string
  record?: any
  source?: string
  description?: string
}

export function FormRow(
  Component: any,
  middleColProps: any = {},
  gridProps: any = {}
) {
  return function GridRow(props: GridRowProps) {
    const { label, description, ...restInput } = props

    const classes = use3ColumnsStyle()

    return (
      <Grid
        container
        item
        xs={12}
        spacing={0}
        alignItems="center"
        {...gridProps}
      >
        <div className={classes.col1}>{label}</div>
        <Component
          {...restInput}
          {...middleColProps}
          className={`${classes.col2} ${middleColProps.className}`}
          label=""
        />
        <div className={classes.col3}>
          {description !== undefined
            ? description
            : `TODO: find real description for ${label}`}
        </div>
      </Grid>
    )
  }
}

export const StaticTextRow = FormRow(StaticTextField)
export const TextInputRow = FormRow(TextInput, { variant: "outlined" })
export const PasswordInputRow = FormRow(PasswordInput, { variant: "outlined" })
export const UrlFieldRow = FormRow(UrlFieldWithCopy, {
  style: { marginBottom: "1rem", marginTop: "1rem" },
})
