import * as React from "react"
import { Route } from "react-router-dom"

import Eye from "../components/Eye"
import Register from "../components/Register"
import { adminPasswordEditRoute } from "../resources/adminPassword"
import { cacheSettingsEditRoute } from "../resources/cacheSettings"
import { companySettingsEditRoute } from "../resources/companySettings"
import { emailSettingsEditRoute } from "../resources/emailSettings"
import { ldapSettingsEditRoute } from "../resources/ldapSettings"
import { samlSettingsEditRoute } from "../resources/samlSettings"

export default [
  adminPasswordEditRoute(),
  cacheSettingsEditRoute(),
  companySettingsEditRoute(),
  ldapSettingsEditRoute(),
  samlSettingsEditRoute(),
  emailSettingsEditRoute(),
  <Route exact path="/eye" component={Eye} noLayout />,
  <Route exact path="/register" component={Register} noLayout />,
]
