import {
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import * as React from "react"

import deviceRegistration from "../api/deviceRegistration"

import Layout from "./PublicLayout"

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})

export default function Register() {
  const classes = useStyles()

  const [successMessage, setSuccessMessage] = React.useState<string>()
  const [errorMessage, setErrorMessage] = React.useState<string>()

  const [email, setEmail] = React.useState<string>()

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  async function handleAdd() {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    if (email) {
      try {
        await deviceRegistration(email)
        setSuccessMessage("User has been emailed registration instructions")
      } catch (error) {
        setErrorMessage((error as any).message)
      }
    }
  }

  return (
    <Layout home title="Add User">
      <Card>
        <CardContent className={classes.content}>
          <Typography align="center">
            Enter an email to register a new user.
          </Typography>
          <TextField
            autoFocus
            label="Email"
            margin="dense"
            onChange={handleChange}
            type="email"
            fullWidth
          />
        </CardContent>
        <CardActions>
          <Button onClick={handleAdd} disabled={!email} type="submit">
            Add
          </Button>
        </CardActions>
        {successMessage && (
          <Snackbar open>
            <Alert severity="success">{successMessage}</Alert>
          </Snackbar>
        )}
        {errorMessage && (
          <Snackbar open>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
        )}
      </Card>
    </Layout>
  )
}
