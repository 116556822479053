import isEqual from "lodash/isEqual"
import { z } from "zod"

import { date } from "./isoDate"

export const schema = z.object({
  id: z.literal("adminPassword"),
  password: z.string().min(8),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export const confirmPasswordSchema = schema
  .extend({
    passwordConfirmation: z.string().min(8),
  })
  .refine((value) => isEqual(value.password, value.passwordConfirmation), {
    message: "passwords do not match",
    path: ["password"],
  })
