import { Location } from "history"
import * as React from "react"
import { Loading } from "react-admin"

import eyeRender from "../../api/eyeRender"
import Error from "./Error"
import Layout from "../PublicLayout"
import ScanInstructions from "./ScanInstructions"

const title = "Scan the CypherEye"

interface Props {
  location: Location
}

export default function Eye({ location }: Props) {
  const [loading, setLoading] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [data, setData] = React.useState<{
    data: string
    message: string
    svg: string
  }>()

  const query = new URLSearchParams(location.search)
  const id = query.get("id")
  const eyeId = Number(id)

  React.useEffect(() => {
    if (!id) {
      setErrorMessage("This link is invalid. It is missing the `id` parameter.")
      return
    }
    if (Number.isNaN(eyeId)) {
      setErrorMessage(
        "This link is invalid. The `id` parameter must be a number."
      )
      return
    }
    const run = async () => {
      try {
        setLoading(true)
        setErrorMessage(undefined)
        const json = await eyeRender(eyeId)
        if (json) {
          setData(json)
          setLoading(false)
        } else {
          setErrorMessage("No eye image returned from server")
        }
      } catch (error: any) {
        setErrorMessage(error.message)
      }
    }
    run()
  }, [location.search, eyeId, id])

  if (errorMessage) {
    return (
      <Layout title={title}>
        <Error message={errorMessage} />
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout title={title}>
        <Loading />
      </Layout>
    )
  }

  if (eyeId && data) {
    return (
      <Layout title={data.message}>
        <ScanInstructions eyeId={eyeId} data={data} />
      </Layout>
    )
  }

  return (
    <Layout title={title}>
      <Error message="Something has gone wrong." />
    </Layout>
  )
}
