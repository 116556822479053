import * as React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { useForm } from "react-final-form"

import { EmailSettings } from "@cyphereye/schema/src/emailSettings"

import EmailValidateButton from "./EmailValidateButton"

const EmailValidateButtonWrapper = () => {
  const form = useForm<EmailSettings>()
  return <EmailValidateButton form={form} />
}

export default function EmailSettingsEditToolbar(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <EmailValidateButtonWrapper />
    </Toolbar>
  )
}
