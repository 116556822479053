import * as React from "react"
import { sanitizeListRestProps, TopToolbar } from "react-admin"

import AddUserButton from "../../components/AddUserButton"

export default function UserListActions(props: any) {
  const { className, exporter, filters, maxResults, ...rest } = props
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <AddUserButton />
    </TopToolbar>
  )
}
