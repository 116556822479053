import { Link, Typography } from "@material-ui/core"
import * as React from "react"
import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  List,
  TextField,
} from "react-admin"

import FacetFilter from "../../components/FacetFilter"
import UserBulkActionButtons from "./UserBulkActionButtons"
import UserListActions from "./UserListActions"

const UserList = (props: any) => {
  return (
    <List
      {...props}
      title="Users"
      actions={<UserListActions />}
      bulkActionButtons={<UserBulkActionButtons />}
      filters={<FacetFilter resource="users" />}
    >
      <Datagrid>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <FunctionField
          label="Devices"
          render={(record: any) => {
            // Tried qs stringify but doesn't seem to work.
            //                             {  "        "  :  "              "  }
            const url = `/devices?filter=%7B%22userId%22%3A%22${record.id}%22%7D`
            return (
              <Link href={url}>
                <Typography color="textPrimary">
                  {record.devices.length}
                </Typography>
              </Link>
            )
          }}
        />
        <DateField source="registrationDate" />
        <DateField source="registrationLastEmailed" />
      </Datagrid>
    </List>
  )
}

export default UserList
