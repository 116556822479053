import { FormApi } from "final-form"
import * as React from "react"
import { Button } from "react-admin"

import { LdapSettings } from "@cyphereye/schema/src/ldapSettings"

import ldapSettingsValidate from "../../api/ldapSettingsValidate"
import LoadingBackdrop from "../../components/LoadingBackdrop"
import useNotifyInfinite from "../../util/useNotifyInfinite"

interface Props {
  form?: FormApi<LdapSettings>
}

export default function LdapValidateButton({ form }: Props) {
  const notifyInfinite = useNotifyInfinite()
  const [loading, setLoading] = React.useState(false)

  async function handleValidate() {
    setLoading(true)
    try {
      const { valid, error } = await ldapSettingsValidate(
        form?.getState().values
      )
      if (valid) {
        notifyInfinite("LDAP Settings are valid", "info")
      } else {
        notifyInfinite(error, "warning")
      }
    } catch (error) {
      notifyInfinite(`Error: ${error.message}`, "warning")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <LoadingBackdrop title="Validating..." />}
      <Button
        label="Test"
        onClick={handleValidate}
        size="medium"
        style={{ marginLeft: "1rem" }}
        variant="contained"
      />
    </>
  )
}
