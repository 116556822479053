import * as React from "react"
import { EditButton, TopToolbar } from "react-admin"

import SendTestEmailButton from "./SendTestEmailButton"

export default function EmailTemplateShowActions({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: any
  resource?: string
}) {
  return (
    <TopToolbar>
      <SendTestEmailButton record={data} />
      <EditButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  )
}
