import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices"

import DeviceList from "./DeviceList"
import DeviceShow from "./DeviceShow"

const resource = {
  icon: ImportantDevicesIcon,
  list: DeviceList,
  show: DeviceShow,
  name: "devices",
}

export default resource
