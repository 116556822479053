import { fetchUtils, AuthProvider } from "ra-core"

interface AuthProviderOptions {
  apiUrl: string
}

// AuthProvider for react-admin.
// We are using an HttpOnly session cookie for secure authentication,
// but storing authentication state in an localStorage.
//
// Also we use react-admin's fetchJson for simplicity,
// even though login/logout do not return JSON
export default ({ apiUrl }: AuthProviderOptions): AuthProvider => {
  return {
    login: async (params: any) => {
      await fetchUtils.fetchJson(`${apiUrl}/login`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params),
      })
      localStorage.setItem("auth", "true")
    },
    logout: async (params: any) => {
      localStorage.removeItem("auth")
      await fetchUtils.fetchJson(`${apiUrl}/logout`, {
        credentials: "include",
        method: "POST",
      })
    },
    checkAuth: (error) => {
      return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject()
    },
    checkError: (error: any) => {
      const status = error.status
      if (status === 401 || status === 403) {
        return Promise.reject()
      }
      return Promise.resolve()
    },
    getPermissions: (params: any) => Promise.resolve(),
    // getIdentity?: () => Promise<UserIdentity>;
  }
}
