import { Card, CardContent, Typography } from "@material-ui/core"
import capitalize from "lodash/capitalize"
import * as React from "react"

interface Props {
  message: string
}

export default function Error({ message }: Props) {
  return (
    <Card>
      <CardContent>
        <Typography align="center" gutterBottom variant="h2">
          We're sorry
        </Typography>
        <Typography align="center" color="error" variant="subtitle1">
          {capitalize(message)}
        </Typography>
      </CardContent>
    </Card>
  )
}
