import { CacheSettings } from "@cyphereye/schema/src/cacheSettings"

import { callAdminApi } from "."

export default async function cacheSettingsValidate(settings?: CacheSettings) {
  return callAdminApi({
    path: "cacheSettings/validate",
    data: settings ? JSON.stringify({ settings }) : undefined,
  })
}
