import * as React from "react"
import { Datagrid, List, ShowButton, TextField } from "react-admin"
import { withStyles } from "@material-ui/core/styles"
import { buttonHover } from "../../components/theme"

const ViewButton = withStyles({
  label: {
    padding: 0,
  },
  button: {
    minWidth: "auto",
    padding: 0,
    "&:hover .MuiButton-label": {
      ...buttonHover,
    },
  },
})(ShowButton)

const TemplateNameColumn = (props: any) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "start" }}
    >
      <TextField {...props} />
      <ViewButton {...props} label="View" icon={null} />
    </div>
  )
}

const EmailTemplateList = (props: any) => (
  <List
    title="Email Templates"
    actions={null}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TemplateNameColumn label="Topic" source="id" />
      <TextField source="description" />
    </Datagrid>
  </List>
)

export default EmailTemplateList
