import PeopleIcon from "@material-ui/icons/People"

import UserList from "./UserList"
// import DeviceShow from "./DeviceShow"

const resource = {
  icon: PeopleIcon,
  list: UserList,
  // show: DeviceShow,
  name: "users",
}

export default resource
