import { z } from "zod"

import { date } from "./isoDate"
import stringToNumber from "./stringToNumber"

export enum CacheProtocol {
  Redis = "redis",
}

export const schema = z.object({
  id: z.literal("cache").default("cache"),
  protocol: z.nativeEnum(CacheProtocol).default(CacheProtocol.Redis),
  host: z.string(),
  port: stringToNumber(z.number().int().min(1).max(65535)),
  password: z.string().nullable(),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type CacheSettings = z.infer<typeof schema>
