import { makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  preTextArea: {
    "& textarea": {
      fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
      whiteSpace: "pre",
    },
  },
}))
