import * as React from "react"
import { samlApiUrl } from "../../api"

import { UrlFieldRow } from "../../components/FormRowThreeCol"

interface Props {
  record?: Record<string, any>
}

export default function IntegrationAuthUrl({ record = {} }: Props) {
  const url = document.location.origin + samlApiUrl(`${record.id}/auth`)

  return (
    <UrlFieldRow
      label="Auth URL"
      description="Login URL used by the Service Provider"
      record={{ url }}
      source="url"
    />
  )
}
