import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"

import IntegrationCreate from "./IntegrationCreate"
import IntegrationEdit from "./IntegrationEdit"
import IntegrationList from "./IntegrationList"

const resource = {
  icon: VerifiedUserIcon,
  create: IntegrationCreate,
  edit: IntegrationEdit,
  list: IntegrationList,
  name: "integrations",
}

export default resource
