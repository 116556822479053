import * as React from "react"
import { Route } from "react-router-dom"
import { WithPermissions } from "react-admin"

// Routes for Settings pages are all pretty much the same
type BaseProps = Pick<
  React.ComponentProps<typeof WithPermissions>,
  "component" | "id" | "resource"
>

interface Props extends BaseProps {
  path: string
}

export default function SettingsPageRoute(props: Props) {
  const { component, id, path, resource } = props
  return (
    <Route
      path={path}
      render={(routeProps: any) => (
        <WithPermissions
          component={component}
          basePath=""
          id={id}
          path={path}
          resource={resource}
          {...routeProps}
        />
      )}
    />
  )
}
