import * as React from "react"

interface Props {
  svg: string
}

export default function InlineSvg({ svg }: Props) {
  const imageRef = React.useRef<HTMLImageElement>(null)

  React.useEffect(() => {
    if (imageRef.current) {
      const blob = new Blob([svg], { type: "image/svg+xml" })
      const url = URL.createObjectURL(blob)
      imageRef.current.src = url
    }
  }, [svg])

  return <img alt="eye" ref={imageRef} />
}
