import * as React from "react"
import {
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from "react-admin"

import ImportMetadataButton from "./ImportMetadataButton"

export default function ListActions(props: any) {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ImportMetadataButton />
    </TopToolbar>
  )
}
