import * as React from "react"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import { DashboardMenuItem, MenuItemLink } from "react-admin"

import AssessmentIcon from "@material-ui/icons/Assessment"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import emailTemplates from "../resources/emailTemplates"
import integrations from "../resources/integrations"
import devices from "../resources/devices"
import users from "../resources/users"

import SettingsMenu from "./SettingsMenu"

type MenuName = "menuSettings" | "menuEmailTemplates"

const initialMenuState: Record<MenuName, boolean> = {
  menuSettings: false,
  menuEmailTemplates: false,
}

interface Props {
  dense: boolean
  logout: () => void
  onMenuClick: () => void
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [menuState, setMenuState] = useState(initialMenuState)
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)

  const handleToggle = (menu: MenuName) => {
    setMenuState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <div>
      <DashboardMenuItem
        onClick={onMenuClick}
        sidebarIsOpen={open}
        leftIcon={<AssessmentIcon />}
      />
      <SettingsMenu
        dense={dense}
        isOpen={menuState.menuSettings}
        onMenuClick={onMenuClick}
        onToggle={() => handleToggle("menuSettings")}
        sidebarIsOpen={open}
      />
      {/* <MenuItemLink
        to={`/objects`}
        primaryText="Objects"
        leftIcon={<objects.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      /> */}
      <MenuItemLink
        to={`/integrations`}
        primaryText="Integrations"
        leftIcon={<integrations.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/devices`}
        primaryText="User Devices"
        leftIcon={<devices.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/users`}
        primaryText="Users"
        leftIcon={<users.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/emailTemplates`}
        primaryText="Email Templates"
        leftIcon={<emailTemplates.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={{ pathname: "/demo-sp-1/login" }}
        primaryText="SAML Login 1"
        leftIcon={<VpnKeyIcon />}
        sidebarIsOpen={open}
        dense={dense}
        target="_blank"
      />
      <MenuItemLink
        to={{ pathname: "/demo-sp-2/login" }}
        primaryText="SAML Login 2"
        leftIcon={<VpnKeyIcon />}
        sidebarIsOpen={open}
        dense={dense}
        target="_blank"
      />
      <MenuItemLink
        to={{ pathname: "/demo-sp-3/login" }}
        primaryText="SAML Login 3"
        leftIcon={<VpnKeyIcon />}
        sidebarIsOpen={open}
        dense={dense}
        target="_blank"
      />
    </div>
  )
}

export default Menu
