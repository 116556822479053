import * as React from "react"
import { DeleteButton, TopToolbar } from "react-admin"

import UpdateStatusButton from "./UpdateStatusButton"

const DeviceShowActions = ({
  basePath,
  data,
  resource,
}: {
  basePath?: string
  data?: any
  resource?: string
}) => {
  return (
    <TopToolbar>
      <UpdateStatusButton record={data} resource={resource} />
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  )
}

export default DeviceShowActions
