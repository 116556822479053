import { Button, SvgIcon, Tooltip } from "@material-ui/core"
import copy from "clipboard-copy"
import React from "react"

interface Props {
  content: string
}

export default function ClipboardCopyButton({ content }: Props) {
  const [showTooltip, setShowTooltip] = React.useState(false)

  const handleCopy = () => {
    copy(content)
    setShowTooltip(true)
  }

  return (
    <Tooltip
      open={showTooltip}
      title="Copied to clipboard!"
      leaveDelay={1_500}
      onClose={() => setShowTooltip(false)}
    >
      <Button onClick={handleCopy}>
        <SvgIcon>
          <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
        </SvgIcon>
      </Button>
    </Tooltip>
  )
}
