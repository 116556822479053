import * as React from "react"

import { schema } from "@cyphereye/schema/src/ldapSettings"

import EditSettingsPage from "../../components/EditSettingsPage"
import { TextInputRow } from "../../components/FormRowThreeCol"
import LdapSettingsEditToolbar from "./LdapSettingsEditToolbar"

const LdapSettingsEdit = (props: any) => {
  return (
    <EditSettingsPage
      {...props}
      toolbar={<LdapSettingsEditToolbar />}
      title="LDAP Settings"
      schema={schema}
    >
      <TextInputRow
        label="Host"
        source="host"
        description="Hostname or IP address of LDAP server"
      />
      <TextInputRow
        label="Port"
        source="port"
        description="Port 389 is the default for TCP and UDP, or on port 636 for LDAPS"
      />
      <TextInputRow
        label="User Name"
        source="username"
        description="Credential used for LDAP queries"
      />
      <TextInputRow
        label="Password"
        source="password"
        description="Credential used for LDAP queries"
      />
      <TextInputRow
        label="Base DN"
        source="baseDn"
        description="Base DN used for LDAP queries"
      />
    </EditSettingsPage>
  )
}

export default LdapSettingsEdit
