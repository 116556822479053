export default function downloadCertificate(certificate: string): void {
  const fakeLink = document.createElement("a")
  fakeLink.style.display = "none"
  document.body.appendChild(fakeLink)
  const blob = new Blob([certificate], { type: "application/x-pem-file" })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `certificate.pem`)
  } else {
    fakeLink.setAttribute("href", URL.createObjectURL(blob))
    fakeLink.setAttribute("download", `certificate.pem`)
    fakeLink.click()
  }
}
