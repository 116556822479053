import Typography from "@material-ui/core/Typography"
import get from "lodash/get"
import mustache from "mustache"
import * as React from "react"
import { RichTextFieldProps } from "ra-ui-materialui/src/field/RichTextField"
import { Show, SimpleShowLayout, TextField, Labeled } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"

import EmailTemplateShowActions from "./EmailTemplateShowActions"

// Copy of RichTextField that renders thru mustache first
const sanitizeRestProps: (props: any) => any = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  emptyText,
  formClassName,
  fullWidth,
  headerClassName,
  label,
  linkType,
  link,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  sortByOrder,
  source,
  textAlign,
  translateChoice,
  ...props
}) => props

const usePreviewStyles = makeStyles({
  fullRow: {
    display: "flex",
  },
})

const Preview: React.FunctionComponent<RichTextFieldProps> = React.memo(
  ({
    className,
    emptyText,
    source,
    record = {},
    stripTags,
    label,
    ...rest
  }) => {
    const classes = usePreviewStyles()

    // Default to empty to handle lazy loading.
    const previewData = get(record, "previewData", {})

    const value = source
      ? mustache.render(get(record, source), previewData)
      : undefined

    return (
      <Labeled
        label={label}
        style={{ display: "block", background: "#eee", color: "#777" }}
        className={classes.fullRow}
      >
        <Typography
          className={className}
          variant="body2"
          component="span"
          {...sanitizeRestProps(rest)}
        >
          {!value && emptyText ? (
            emptyText
          ) : (
            <span dangerouslySetInnerHTML={{ __html: value || "" }} />
          )}
        </Typography>
      </Labeled>
    )
  }
)

const EmailTemplateTitle = ({ record }: { record?: any }) => {
  return <span>{record ? record.id : "Email Template"}</span>
}

const EmailTemplateShow = (props: any) => (
  <Show
    actions={<EmailTemplateShowActions />}
    title={<EmailTemplateTitle />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="description" />
      <Preview source="subject" label="Subject" />
      <Preview source="source" label="Content" />
    </SimpleShowLayout>
  </Show>
)

export default EmailTemplateShow
