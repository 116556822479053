import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}))

interface Props {
  title: string
  delayMs?: number
}

export default function LoadingBackdrop({ title, delayMs = 200 }: Props) {
  const classes = useStyles()
  const [show, setShow] = React.useState(delayMs === 0)

  React.useEffect(() => {
    if (delayMs > 0) {
      const timer = setTimeout(() => setShow(true), delayMs)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [delayMs])

  if (!show) {
    return null
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <Typography variant="h4" color="inherit" className={classes.title}>
        {title}
      </Typography>
      <CircularProgress />
    </Backdrop>
  )
}
