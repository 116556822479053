import * as React from "react"
import { SaveButton, Toolbar } from "react-admin"
import { useForm } from "react-final-form"

import { EmailTemplate } from "@cyphereye/schema/src/emailTemplate"

import SendTestEmailButton from "./SendTestEmailButton"

const SendTestEmailButtonWrapper = () => {
  const form = useForm<EmailTemplate>()

  // Ensure we fetch fresh form values as the button is already rendered.
  const record = () => form.getState().values

  return (
    <span style={{ marginLeft: "1rem" }}>
      <SendTestEmailButton record={record} />
    </span>
  )
}

const EmailTemplateEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
    <SendTestEmailButtonWrapper />
  </Toolbar>
)

export default EmailTemplateEditToolbar
