import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import IconAdd from "@material-ui/icons/Add"
import * as React from "react"
import { Button } from "react-admin"
import { FormApi } from "final-form"
import { useHistory } from "react-router-dom"

import importMetadata from "./importMetadata"
import useNotifyInfinite from "../../util/useNotifyInfinite"

interface Props {
  form?: FormApi
}

export default function ImportMetadataButton({ form }: Props) {
  const [open, setOpen] = React.useState(false)
  const [url, setUrl] = React.useState<string>()
  const history = useHistory()
  const notifyInfinite = useNotifyInfinite()

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUrl(event.target.value)
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  async function handleImport() {
    if (url) {
      try {
        const record = await importMetadata(url)
        if (form) {
          form.batch(() => {
            // Flatten nested meta object into keys such as meta.entityId
            const { meta } = record
            for (const [key, value] of Object.entries(meta)) {
              const formKey = `meta.${key}`
              form.change(formKey, value)
            }
          })
          handleClose()
        } else {
          history.push("/integrations/create", { record })
        }
      } catch (error) {
        notifyInfinite(error.message, "warning")
      }
    }
  }

  const dialogContentText = `Enter the URL for the Service Provider's SAML metadata to ${
    form ? "update the" : "create an"
  } integration.`

  return (
    <>
      <Button label="Import SAML Metadata" onClick={handleOpen}>
        <IconAdd />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Import SAML Metadata</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          <TextField
            autoFocus
            label="URL"
            margin="dense"
            onChange={handleChange}
            type="url"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleClose} color="primary" />
          <Button
            label="Import"
            onClick={handleImport}
            color="primary"
            disabled={!url}
            type="submit"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
