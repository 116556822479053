import { z } from "zod"

// Useful for conditionally requiring fields via `superRefine`.
export default function requireFields<T extends object>(
  value: T | undefined,
  ctx: z.RefinementCtx,
  path: Array<string>,
  fields: Array<keyof T>
) {
  if (value) {
    for (const field of fields) {
      if (!value[field]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: [...path, field.toString()],
        })
      }
    }
  }
}
