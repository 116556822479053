import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import { ThemeColors } from "./theme"

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  separator: {
    backgroundColor: ThemeColors.DarkGray,
    display: "flex",
    flex: 1,
    height: 1,
  },
  text: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
})

export default function TextSeparator({ text }: { text: string }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.separator} />
      <Typography className={classes.text}>{text}</Typography>
      <div className={classes.separator} />
    </div>
  )
}
