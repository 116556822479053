import * as React from "react"
import { Show, SimpleShowLayout } from "react-admin"

import DeviceShowActions from "./DeviceShowActions"
import {
  TextFieldRow,
  DateFieldRow,
  EmailFieldRow,
} from "../../components/FormRowTwoCol"

const DeviceShowTitle = ({ record }: { record?: any }) => {
  return <span>{record ? record.alias : "Email Template"}</span>
}

const DeviceShow = (props: any) => (
  <Show actions={<DeviceShowActions />} {...props} title={<DeviceShowTitle />}>
    <SimpleShowLayout>
      <TextFieldRow source="name" label="Device" />
      <TextFieldRow source="alias" label="Device Name" />
      <TextFieldRow source="userName" label="Username" />
      <EmailFieldRow source="userEmail" label="Email" />
      <TextFieldRow source="status" label="Status" />
      <DateFieldRow source="created" label="Added" />
      <DateFieldRow source="updated" label="Updated" />
    </SimpleShowLayout>
  </Show>
)

export default DeviceShow
