import { z } from "zod"

import { date } from "./isoDate"
import pemCertificate from "./pemCertificate"
import pemPrivateKey from "./pemPrivateKey"

export const schema = z.object({
  id: z.literal("saml").default("saml"),
  entityId: z.string(),
  certificate: pemCertificate,
  privateKey: pemPrivateKey,
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type SamlSettings = z.infer<typeof schema>
