import * as React from "react"
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin"
import { z } from "zod"

import makeTransformer from "../util/makeTransformer"
import makeValidator from "../util/makeValidator"
import useNotifyInfinite from "../util/useNotifyInfinite"

const EditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
)

interface Props {
  toolbar?: React.Component
  schema: z.Schema<any>
  [rest: string]: any
}

export default function EditSettingsPage(props: Props) {
  const { children, toolbar, schema, ...rest } = props
  const { basePath, path } = props
  const notify = useNotify()
  const notifyInfinite = useNotifyInfinite()
  const redirect = useRedirect()

  const transform = makeTransformer(schema)
  const validate = makeValidator(schema)

  // Override to remain on the edit page.
  const onSuccess = () => {
    notify("ra.notification.updated", "info", { smart_count: 1 }, false)
    redirect("edit", basePath, path.replace(/^\//, ""))
  }

  // Override to persist the error notification.
  const onFailure = (error: any) => {
    notifyInfinite(
      typeof error === "string"
        ? error
        : error.message || "ra.notification.http_error",
      "warning"
    )
  }

  return (
    <Edit
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
      undoable={false}
      {...rest}
    >
      <SimpleForm
        toolbar={toolbar || <EditToolbar />}
        validate={validate}
        warnWhenUnsavedChanges
      >
        {children}
      </SimpleForm>
    </Edit>
  )
}
