import { EmailSettings } from "@cyphereye/schema/src/emailSettings"

import { callAdminApi } from "."

export default async function emailSettingsValidate(settings?: EmailSettings) {
  return callAdminApi({
    path: "emailSettings/validate",
    data: settings ? JSON.stringify({ settings }) : undefined,
  })
}
