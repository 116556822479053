import { z } from "zod"

import stringToNumber from "./stringToNumber"
import { date } from "./isoDate"

export const schema = z.object({
  id: z.literal("ldap").default("ldap"),
  host: z.string(),
  port: stringToNumber(z.number().int().min(1).max(65535)),
  username: z.string(),
  password: z.string(),
  baseDn: z.string(),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type LdapSettings = z.infer<typeof schema>
