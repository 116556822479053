import isValid from "date-fns/isValid"
import parseISO from "date-fns/parseISO"
import { z } from "zod"

export const isoDate = z
  .string()
  .refine((value) => isValid(parseISO(value)))
  .transform(parseISO)

export const date = z.date().or(isoDate)
