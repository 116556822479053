import * as React from "react"
import { Button } from "react-admin"

import deviceRegistrationBulk from "../../api/deviceRegistrationBulk"
import useNotifyInfinite from "../../util/useNotifyInfinite"

function SendBulkDeviceRegistrationButton({
  selectedIds,
}: {
  selectedIds: Array<string>
}) {
  const [disabled, setDisabled] = React.useState(false)
  const notifyInfinite = useNotifyInfinite()

  async function onClick() {
    setDisabled(true)

    try {
      const { errors } = await deviceRegistrationBulk(selectedIds)
      if (errors === 0) {
        notifyInfinite("Registration has been emailed to the users.", "info")
      } else {
        const success = selectedIds.length - errors
        const message = `Registration has been emailed to ${success}/${selectedIds.length} of the users.`
        notifyInfinite(message, "warning")
      }
    } catch (error) {
      notifyInfinite(error.message, "warning")
    } finally {
      setDisabled(false)
    }
  }
  return (
    <Button
      label="Send Registration Email"
      disabled={disabled}
      onClick={onClick}
    />
  )
}

export default function UserBulkActionButtons(props: any) {
  return <SendBulkDeviceRegistrationButton {...props} />
}
