import SubjectIcon from "@material-ui/icons/Subject"

import ObjectList from "./ObjectList"
import ObjectShow from "./ObjectShow"

const resource = {
  icon: SubjectIcon,
  list: ObjectList,
  show: ObjectShow,
  name: "objects",
}

export default resource
