import * as React from "react"

import { schema } from "@cyphereye/schema/src/cacheSettings"

import EditSettingsPage from "../../components/EditSettingsPage"
import { TextInputRow } from "../../components/FormRowThreeCol"

import CacheSettingsEditToolbar from "./CacheSettingsEditToolbar"

const CacheSettings = (props: any) => {
  return (
    <EditSettingsPage
      {...props}
      toolbar={<CacheSettingsEditToolbar />}
      title="Cache Settings"
      schema={schema}
    >
      <TextInputRow
        label="Host"
        description="Name of Redis server"
        source="host"
      />
      <TextInputRow
        label="Port"
        description="Redis server port"
        source="port"
      />
      <TextInputRow
        label="Password"
        description="Password to Redis server"
        source="password"
      />
    </EditSettingsPage>
  )
}

export default CacheSettings
