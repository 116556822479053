import {
  AppBar,
  Container,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core"
import HomeIcon from "@material-ui/icons/Home"
import * as React from "react"
import { Helmet } from "react-helmet"

import theme from "./theme"

interface Props {
  children: React.ReactElement
  home?: boolean
  title?: string
}

export default function Layout({
  children,
  home = false,
  title = "CypherEye",
}: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth={false}>
        <AppBar color="primary" position="static">
          <Toolbar
            style={{ justifyContent: home ? "space-between" : "center" }}
          >
            <Typography variant="h5" color="inherit">
              {title}
            </Typography>
            {home && (
              <IconButton color="inherit" style={{ visibility: "hidden" }}>
                <HomeIcon fontSize="large" />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {children}
      </Container>
    </ThemeProvider>
  )
}
