import * as React from "react"
import { Datagrid, DateField, List, ShowButton, TextField } from "react-admin"

import ObjectFilter from "./ObjectFilter"

const ObjectList = (props: any) => (
  <List {...props} filters={<ObjectFilter />}>
    <Datagrid>
      <TextField source="user" />
      <TextField source="settings" />
      <TextField source="permissions" />
      <TextField source="type" />
      <TextField source="data" />
      <TextField source="expires" />
      <DateField source="created" />
      <DateField source="updated" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default ObjectList
