import { Typography } from "@material-ui/core"
import * as React from "react"

export default function StaticTextField({
  className,
  value,
}: {
  className: string
  value: string
}) {
  return <Typography className={className}>{value}</Typography>
}
