import * as React from "react"

import { schema } from "@cyphereye/schema/src/samlSettings"

import EditSettingsPage from "../../components/EditSettingsPage"
import { TextInputRow } from "../../components/FormRowThreeCol"
import CertificateShow from "./CertificateShow"
import PrivateKeyShow from "./PrivateKeyShow"

const SamlSettingsEdit = (props: any) => {
  return (
    <EditSettingsPage {...props} title="SAML Settings" schema={schema}>
      <TextInputRow
        label="IdP Entity ID"
        description="String or URI for the Identity Provider name"
        source="entityId"
      />
      <CertificateShow />
      <PrivateKeyShow />
    </EditSettingsPage>
  )
}

export default SamlSettingsEdit
