import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import IconEmail from "@material-ui/icons/Email"
import * as React from "react"
import { Button } from "react-admin"

import { EmailTemplate } from "@cyphereye/schema/src/emailTemplate"

import sendTestEmail from "../../api/sendTestEmail"
import useNotifyInfinite from "../../util/useNotifyInfinite"

export default function SendTestEmailButton({
  record,
}: {
  // Callback form allows fetching fresh form data.
  record: EmailTemplate | (() => EmailTemplate)
}) {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState<string>()
  const notifyInfinite = useNotifyInfinite()

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  async function handleSend() {
    if (email) {
      try {
        const data = typeof record === "function" ? record() : record
        await sendTestEmail(email, data)
        setOpen(false)
        notifyInfinite("Email has been sent.", "info")
      } catch (error) {
        notifyInfinite(error.message, "warning")
      }
    }
  }

  return (
    <>
      <Button label="Send Test Email" onClick={handleOpen}>
        <IconEmail />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send Test Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter user's email to send a test email to for this template.
          </DialogContentText>
          <TextField
            autoFocus
            label="Email"
            margin="dense"
            onChange={handleChange}
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleClose} color="primary" />
          <Button
            label="Send"
            onClick={handleSend}
            color="primary"
            disabled={!email}
            type="submit"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
