import SettingsPageRoute from "../../routes/SettingsPageRoute"
import CompanySettingsEdit from "./CompanySettingsEdit"

// Don't render as a component else the route does not work
const companySettingsEditRoute = () =>
  SettingsPageRoute({
    component: CompanySettingsEdit,
    id: "company",
    path: "/organization",
    resource: "companySettings",
  })

export default companySettingsEditRoute
