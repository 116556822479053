import SettingsPageRoute from "../../routes/SettingsPageRoute"
import EmailSettingsEdit from "./EmailSettingsEdit"

// Don't render as a component else the route does not work
const EmailSettingsEditRoute = () =>
  SettingsPageRoute({
    component: EmailSettingsEdit,
    id: "email",
    path: "/email",
    resource: "emailSettings",
  })

export default EmailSettingsEditRoute
