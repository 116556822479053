import { NotificationType } from "ra-core"
import React from "react"
import { useNotify } from "react-admin"

export default function useNotifyInfinite() {
  const notify = useNotify()

  return React.useCallback(
    (message: string, type: NotificationType = "info") => {
      notify(
        message,
        type,
        {},
        false,
        1_000 * 60 * 60 * 24 // null doesn't work
      )
    },
    [notify]
  )
}
