import { z } from "zod"

import aesKey from "./aesKey"
import { date } from "./isoDate"
import jsonString from "./jsonString"
import requireFields from "./requireFields"

export enum EyeType {
  CypherVaultArchive = "CypherVaultArchive",
  RegisterDevice = "RegisterDevice",
  SignIn = "SignIn",
}

export enum EyeStatus {
  DeviceNotRegistered = "DeviceNotRegistered",
  Pending = "Pending",
  Scanned = "Scanned",
}

// A discriminated union would be better to avoid all the optional meta fields,
// but it's harder to parse the errors.
const baseSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(EyeType),
  status: z.nativeEnum(EyeStatus),
  expires: z.optional(date).nullable(),
  created: z.optional(date),
  updated: z.optional(date),
  meta: z
    .object({
      // CypherVaultArchive
      archiveKey: z.optional(aesKey),
      archiveHash: z.optional(aesKey),
      sourceHash: z.optional(aesKey),
      lastScanType: z.optional(z.enum(["encrypt", "decrypt"])),
      // RegisterDevice
      email: z.optional(z.string().email()),
      // SignIn
      integrationId: z.optional(z.string()),
      redirect: z.optional(z.string().url()),
      samlRequest: z.optional(
        z.object({
          body: z.optional(jsonString),
          method: z.optional(z.string()),
          query: z.optional(jsonString),
        })
      ),
      // CypherVaultArchive/SignIn and Scanned
      userId: z.optional(z.string()),
    })
    .default({}),
})

const refinement = (value: Partial<Eye>, ctx: z.RefinementCtx) => {
  switch (value.type) {
    case EyeType.CypherVaultArchive:
      requireFields(value.meta, ctx, ["meta"], ["archiveKey", "sourceHash"])
      if (value.status === EyeStatus.Scanned) {
        requireFields(value.meta, ctx, ["meta"], ["userId"])
      }
      break
    case EyeType.RegisterDevice:
      requireFields(value.meta, ctx, ["meta"], ["email"])
      break
    case EyeType.SignIn:
      requireFields(
        value.meta,
        ctx,
        ["meta"],
        ["integrationId", "redirect", "samlRequest"]
      )
      requireFields(
        value.meta?.samlRequest,
        ctx,
        ["meta", "samlRequest"],
        ["body", "method", "query"]
      )
      if (value.status === EyeStatus.Scanned) {
        requireFields(value.meta, ctx, ["meta"], ["userId"])
      }
      break
    default:
      break
  }
}

export const schema = baseSchema.superRefine(refinement)

export const partialSchema = baseSchema.partial().superRefine(refinement)

export type Eye = z.infer<typeof schema>
