import SettingsIcon from "@material-ui/icons/Settings"

import SettingList from "./SettingList"

const resource = {
  icon: SettingsIcon,
  list: SettingList,
  name: "settings",
}

export default resource
