import { z } from "zod"

import { date } from "./isoDate"

export enum DeviceStatus {
  Approved = "approved",
  Pending = "pending",
  Suspended = "suspended",
}

export enum DeviceType {
  Device = "Device",
}

export const schema = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  status: z.nativeEnum(DeviceStatus),
  type: z.nativeEnum(DeviceType),
  refreshToken: z.string().max(512),
  name: z.string(),
  alias: z.string(),
  discriminator: z.string(),
  created: z.optional(date),
  updated: z.optional(date),
})

export const partialSchema = schema.partial()

export type Device = z.infer<typeof schema>
