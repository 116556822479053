import { FormDataConsumer } from "ra-core"
import * as React from "react"
import { TextInput } from "react-admin"

import { schema } from "@cyphereye/schema/src/emailSettings"

import EditSettingsPage from "../../components/EditSettingsPage"
import { FormRow } from "../../components/FormRowThreeCol"

import EmailSettingsEditToolbar from "./EmailSettingsEditToolbar"

const TextInputRow = FormRow(
  TextInput,
  { variant: "outlined" },
  { style: { width: "100%" } }
)

const DataConsumerRow = (props: any) => {
  const { protocol, label, description, source } = props
  return (
    <FormDataConsumer>
      {({ formData }: { formData: any }) =>
        formData.protocol === protocol && (
          <TextInputRow
            label={label}
            description={description}
            source={source}
          />
        )
      }
    </FormDataConsumer>
  )
}

const EmailSettingsEdit = (props: any) => {
  return (
    <EditSettingsPage
      {...props}
      toolbar={<EmailSettingsEditToolbar />}
      title="Email Settings"
      schema={schema}
    >
      <TextInputRow
        label="Email Display Name"
        source="from"
        description="From name to appear in recipient's inbox"
      />

      <DataConsumerRow
        protocol="smtp"
        label="Host"
        description="IP address or hostname of email server"
        source="host"
      />
      <DataConsumerRow
        protocol="smtp"
        label="Port"
        description="Port number of email server"
        source="port"
      />
      <DataConsumerRow
        protocol="smtp"
        label="Email Address"
        description="Mailbox to send email notifications"
        source="username"
      />
      <DataConsumerRow
        protocol="smtp"
        label="Email Password"
        description="Mailbox to send email notifications"
        source="password"
      />
      {/* <DataConsumerRow
        protocol="mailgun"
        label="mailgunApiKey"
        source="mailgunApiKey"
      />
      <DataConsumerRow
        protocol="mailgun"
        label="mailgunDomain"
        source="mailgunDomain"
      /> */}
    </EditSettingsPage>
  )
}

export default EmailSettingsEdit
