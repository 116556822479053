import { SamlMetadata } from "@cyphereye/schema/src/samlMetadata"

import integrationMetadata from "../../api/integrationMetadata"

interface IntegrationRecord {
  meta: {
    entityId: string
    assertionConsumerService: string
    certificate: string
    metadataUrl: string
  }
}

export default async function importMetadata(url: string) {
  const metadata = await integrationMetadata(url)
  return makeRecord(url, metadata)
}

function makeRecord(
  metadataUrl: string,
  attrs: SamlMetadata
): IntegrationRecord {
  const { entityId, assertionConsumerService, certificates } = attrs

  return {
    meta: {
      entityId,
      assertionConsumerService,
      certificate: certificates[0].trim(),
      metadataUrl,
    },
  }
}
