import * as React from "react"
import { Grid } from "@material-ui/core"
import { DateField, EmailField, PasswordInput, TextField } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"

const use2ColumnsStyle = makeStyles((theme) => ({
  col1: {
    flexBasis: "50%",
    marginBottom: "23px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  col2: {
    flexBasis: "33%",
    marginTop: "0",
    marginBottom: "23px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxSizing: "border-box",
  },
}))

type GridRowProps = {
  label: string
  record?: any
  source: string
  description?: string
}

export function FormRow(
  Component: any,
  middleColProps: any = {},
  gridProps: any = {}
) {
  return function GridRow(props: GridRowProps) {
    const { label, description, ...restInput } = props

    const classes = use2ColumnsStyle()

    return (
      <Grid
        container
        item
        xs={12}
        spacing={0}
        alignItems="center"
        {...gridProps}
      >
        <div className={classes.col1}>{label}</div>
        <Component
          {...restInput}
          {...middleColProps}
          className={`${classes.col2} ${middleColProps.className}`}
          label=""
        />
        {/* <div className={classes.col3}>
                    {description || `TODO: find real description for ${label}`}
                </div> */}
      </Grid>
    )
  }
}

export const TextFieldRow = FormRow(TextField)
export const DateFieldRow = FormRow(DateField)
export const EmailFieldRow = FormRow(EmailField)
export const PasswordInputRow = FormRow(PasswordInput, { variant: "outlined" })
