import * as React from "react"
import { MenuItemLink } from "react-admin"

import BusinessIcon from "@material-ui/icons/Business"
import CachedIcon from "@material-ui/icons/Cached"
import EmailIcon from "@material-ui/icons/Email"
import PeopleIcon from "@material-ui/icons/People"
import SettingsSystemDaydreamIcon from "@material-ui/icons/SettingsSystemDaydream"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import SubMenu from "./SubMenu"
import settings from "../resources/settings"

interface MenuItem {
  to: string
  primaryText: string
  leftIcon: React.ReactElement
}

const menuItems: Array<MenuItem> = [
  {
    to: "/adminPassword",
    primaryText: "Admin Password",
    leftIcon: <SupervisorAccountIcon />,
  },
  {
    to: "/organization",
    primaryText: "Organization",
    leftIcon: <BusinessIcon />,
  },
  {
    to: "/ldap",
    primaryText: "LDAP",
    leftIcon: <PeopleIcon />,
  },
  {
    to: "/cache",
    primaryText: "Cache",
    leftIcon: <CachedIcon />,
  },
  {
    to: "/email",
    primaryText: "Email",
    leftIcon: <EmailIcon />,
  },
  {
    to: "/saml",
    primaryText: "SAML",
    leftIcon: <SettingsSystemDaydreamIcon />,
  },
]

interface Props {
  dense: boolean
  isOpen: boolean
  onMenuClick: () => void
  onToggle: () => void
  sidebarIsOpen: boolean
}

export default function SettingsMenu({
  dense,
  isOpen,
  onMenuClick,
  onToggle,
  sidebarIsOpen,
}: Props) {
  return (
    <SubMenu
      handleToggle={onToggle}
      isOpen={isOpen}
      sidebarIsOpen={sidebarIsOpen}
      name="Settings"
      icon={<settings.icon />}
      dense={dense}
    >
      {menuItems.map(({ to, primaryText, leftIcon }) => (
        <MenuItemLink
          key={to}
          to={to}
          primaryText={primaryText}
          leftIcon={leftIcon}
          onClick={onMenuClick}
          sidebarIsOpen={sidebarIsOpen}
          dense={dense}
        />
      ))}
    </SubMenu>
  )
}
