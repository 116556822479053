import { Button } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import capitalize from "lodash/capitalize"
import * as React from "react"
import { Filter, TextInput, useListContext } from "react-admin"

import { buttonHover, ThemeColors } from "./theme"
import searchFacets from "../api/searchFacets"

const actionButtonStyles = {
  root: {
    color: `${ThemeColors.Orange}`,
    "&:hover": {
      ...buttonHover,
    },
  },
}

const ButtonLink = withStyles(actionButtonStyles)(Button)

const Pipe = () => <span style={{ marginTop: "0.25rem" }}>|</span>

interface Props {
  resource: string
  [key: string]: any
}

export default function FacetFilter({ resource, ...props }: Props) {
  const ctx = useListContext()
  const { filterValues, setFilters } = ctx

  const [facets, setFacets] = React.useState<Record<string, number>>({})
  React.useEffect(() => {
    async function run() {
      const result = await searchFacets(resource)
      setFacets(result)
    }
    run()
  }, [])

  const [firstTime, setFirstTime] = React.useState(true)

  if (firstTime && filterValues?.facet) {
    setFilters({})
  }

  React.useEffect(() => {
    setFirstTime(false)
  }, [])

  const [activeFacet, setActiveFacet] = React.useState("all")

  const FacetButton = ({ facet }: { facet: string }) => {
    const onClick = () => {
      if (facet === "all") {
        setFilters({})
      } else {
        setFilters({ facet })
      }
      setActiveFacet(facet)
    }

    return (
      <ButtonLink
        style={activeFacet === facet ? { ...buttonHover } : {}}
        onClick={onClick}
      >
        {capitalize(facet)} ({facets[facet]})
      </ButtonLink>
    )
  }

  const FacetButtons = Object.keys(facets).map((facet, index) => (
    <React.Fragment key={facet}>
      {index > 0 && <Pipe />}
      <FacetButton facet={facet} />
    </React.Fragment>
  ))

  return (
    <div style={{ display: "flex", placeItems: "center" }}>
      <Filter {...props} variant="outlined">
        <TextInput label="Search" resettable source="q" alwaysOn />
      </Filter>
      <div style={{ display: "flex", alignItems: "center", height: "20px" }}>
        {FacetButtons}
      </div>
    </div>
  )
}
